import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";
import colors from "../utils/colors";
import { windowSize } from "../utils/responsive";
import facebook from "../images/icons/facebook.svg";
import twitter from "../images/icons/twitter.svg";
import instagram from "../images/icons/instagram.svg";
import linkedin from "../images/icons/linkedin.svg";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "stockperks.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
      <>
        <FooterContent>
          <FooterLeftContent>
            <Link to="/">
              <Img
                fixed={data.logo.childImageSharp.fixed}
                alt={"Stockperks Logo"}
              />
            </Link>
          </FooterLeftContent>
          <FooterRightContent>
            <div>
              <strong>Company</strong>
              <ul>
                <li>
                  <Link to="/">Stockperks</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div>
              <strong>Resources</strong>
              <ul>
                <li>
                  <Link to="/blog">News & Blog</Link>
                </li>
              </ul>
            </div>
          </FooterRightContent>
        </FooterContent>
        <FooterCopyright>
          <p>
            © Stockperks {new Date().getFullYear()}. All Rights Reserved.
            Stockperks does not recommend or endorse the purchase of any
            securities, or provide financial or investment advice.
          </p>
        </FooterCopyright>
        <FooterLinks>
          <FooterLink to="/terms">Terms of Service</FooterLink>
          <FooterLink to="/privacy">Privacy Policy</FooterLink>
        </FooterLinks>
      </>
  );
};

export default Footer;



const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  margin: auto;
  padding: 30px;
  background-color: ${colors.cobalt};
  color: ${colors.white};
  a {
    color: ${colors.white};
  }
`;

const FooterLeftContent = styled.div`
  margin-right: 40px;
  flex-grow: 1;
  padding-bottom: 10px;
`;

const FooterRightContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  max-width: 300px;
  justify-content: space-evenly;

  > * {
    flex: 1;
    margin-right: 10px;
  }

  > * :last-child {
    margin-right: 0;
  }

  ul {
    padding: 0;
    li {
      line-height: 14px;
      list-style-type: none;
      margin: 10px 0;
      i {
        color: ${colors.gray};
        font-size: 10px;
      }
    }
  }
`;

const FooterSocialIconContainer = styled.div`
  margin-top: 20px;
  > * {
    cursor: pointer;
    margin-right: 10px;
  }

  @media (max-width: ${windowSize.medium}px) {
    margin-bottom: 20px;
  }
`;

const FooterCopyright = styled.div`
  background-color: ${colors.cobalt};
  font-size: 14px;
  text-align: center;
  padding: 20px;
  p {
    color: ${colors.offWhite};
    opacity: 0.5;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const FooterLink = styled(Link)`
  color: ${colors.offWhite};
  opacity: 0.8;
  text-decoration: none;
  margin: 0 10px;
`;
