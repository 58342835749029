/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Nav from "./Nav";
import styled from "styled-components";
import Footer from "./Footer";
import GlobalStyle from "./GlobalStyle";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyle />
      <Nav siteTitle={data.site.siteMetadata.title} />
      <PageContent>{children}</PageContent>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;

const PageContent = styled.main`
  display: flex;
  flex-direction: column;
`;
